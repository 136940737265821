.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/********** Template CSS **********/
:root {
  --primary: #00acd9;
  --secondary: #5F656F;
  --light: #F5F5F5;
  --dark: #02245B;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}
.bg-primary {
  background-color: #00acd9 !important;
}
h1,
h2,
.h1,
.h2,
.fw-bold {
  font-weight: 700 !important;
}

h3,
h4,
.h3,
.h4,
.fw-medium {
  font-weight: 600 !important;
}

h5,
h6,
.h5,
.h6,
.fw-semi-bold {
  font-weight: 500 !important;
}

.display-2{
  font-size: 22px;
  font-weight: 200;
}
/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  transition: .5s;
  font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}


.topbar-right {
  position: relative;
  background: #00acd9;
}

.topbar-right::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 100%;
  top: 0;
  left: -15px;
  transform: skewX(-30deg);
  background-color: #00acd9;
}


/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand {
  position: relative;
  padding-right: 50px;
  height: 75px;
  display: flex;
  align-items: center;
  background: #fff;
}

.navbar .navbar-brand::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 100%;
  top: 0;
  right: -25px;
  transform: skewX(-30deg);
  background-color: var(--primary);
}

.navbar .navbar-nav .nav-link {
  margin-right: 35px;
  padding: 20px 0;
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #00acd9;
}
a.btn.btn-primary.py-3.px-5.animated.slideInRight {
  background-color: #00acd9;
  border: none;
}
.text-primary {
  color: #00acd9 !important;
}
.btn-primary {
  color: #fff;
  background-color: #00acd9;
  border-color: #00acd9;
}
.fab {
  font-family: "Font Awesome 5 Brands";
  color: #fff;
}
.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}
.Logo {
  max-width: 120%;
  height: 120%;
}

/*** Header ***/

.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, rgba(2, 36, 91, 1) 0%, rgba(2, 36, 91, 0) 100%);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3.5rem;
  height: 3.5rem;
  background-color: var(--primary);
  border: 15px solid var(--primary);
  border-radius: 3.5rem;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
      position: relative;
      min-height: 450px;
  }
  
  #header-carousel .carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}

.page-header {
  background: linear-gradient(to right, rgba(2, 36, 91, 1) 0%, rgba(2, 36, 91, 0) 100%), url("../src/assets/images/service-3.jpg") center center no-repeat;
  background-size: cover;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
  color: var(--light);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
  font-size: 18px;
  color: var(--light);
}


/*** Facts ***/
.facts {
  position: relative;
  margin: 6rem 0;
  background: var(--dark);
}

.facts .border {
  border-color: rgba(255, 255, 255, .1) !important;
}


/*** Features ***/
.btn-play {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  display: block;
  box-sizing: content-box;
  width: 16px;
  height: 26px;
  border-radius: 100%;
  border: none;
  outline: none !important;
  padding: 18px 20px 20px 28px;
  background: var(--primary);
}

@media (max-width: 992px) {
  .btn-play {
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
  }
}

.btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: var(--primary);
  border-radius: 100%;
  animation: pulse-border 1500ms ease-out infinite;
}

.btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: var(--primary);
  border-radius: 100%;
  transition: all 200ms;
}

.btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  left: -1px;
  border-left: 16px solid #FFFFFF;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}

@keyframes pulse-border {
  0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
  }

  100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
      opacity: 0;
  }
}

.modal-video .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

.modal-video .modal-body {
  position: relative;
  padding: 0px;
}

.modal-video .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #FFFFFF;
  background: #000000;
  opacity: 1;
}


/*** Service ***/
.service-item {
  position: relative;
  margin: 65px 0 25px 0;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.service-item .service-img {
  position: absolute;
  padding: 12px;
  width: 130px;
  height: 130px;
  top: -65px;
  left: 50%;
  transform: translateX(-50%);
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .09);
  z-index: 2;
}

.service-item .service-detail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.service-item .service-title {
  position: absolute;
  padding: 65px 30px 25px 30px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #FFFFFF;
  transition: .5s;
}

.service-item:hover .service-title {
  top: -100%;
}

.service-item .service-text {
  position: absolute;
  overflow: hidden;
  padding: 65px 30px 25px 30px;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  display: flex;
  align-items: center;
  text-align: center;
  background: rgba(2, 36, 91, .7);
  transition: .5s;
}

.service-item:hover .service-text {
  top: 0;
}

.service-item .service-text::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100px;
  top: -100%;
  left: 0;
  transform: skewY(-12deg);
  background: #FFFFFF;
  transition: .5s;
}

.service-item:hover .service-text::before {
  top: -55px;
}

.service-item .btn {
  position: absolute;
  width: 130px;
  height: 50px;
  left: 50%;
  bottom: -25px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  background: #FFFFFF;
  border: none;
  box-shadow: 0 0 45px rgba(0, 0, 0, .09);
  z-index: 2;
}

.service-item .btn:hover {
  color: #FFFFFF;
  background: var(--primary);
}


/*** Project ***/
.project-carousel {
  position: relative;
  background: var(--dark);
}

.project-item {
  position: relative;
  display: block;
}

.project-item img {
  transition: .5s;
}

.project-item:hover img,
.project-carousel .owl-item.center img {
  margin-top: -60px;
}

.project-item .project-title {
  position: absolute;
  padding: 0 15px;
  width: 100%;
  height: 80px;
  bottom: -110px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--dark);
  transition: .5s;
}

.project-item:hover .project-title,
.project-carousel .owl-item.center .project-title  {
  bottom: -60px;
}

.project-item .project-title::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 30px;
  top: -15px;
  left: 0;
  transform: skewY(-5deg);
  background: var(--dark);
  transition: .5s;
}

.project-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 45px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  transition: .5s;
  opacity: 0;
  z-index: 1;
}

.project-carousel:hover .owl-nav {
  opacity: 1;
}

.project-carousel .owl-nav .owl-prev,
.project-carousel .owl-nav .owl-next {
  margin: 0 30px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  border-radius: 45px;
  font-size: 22px;
  transition: .5s;
}


/*** Team ***/
.team-item .team-social {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  display: flex;
  align-items: center;
  background: var(--primary);
  transition: .5s;
}

.team-item:hover .team-social {
  left: 0;
}


/*** Testimonial ***/
.testimonial-carousel::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}

.testimonial-carousel::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}

@media (min-width: 768px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
      width: 200px;
  }
}

@media (min-width: 992px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
      width: 300px;
  }
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 350px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: .5s;
  z-index: 1;
}

.testimonial-carousel:hover .owl-nav {
  width: 300px;
  opacity: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  color: var(--primary);
  font-size: 45px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.testimonial-carousel .testimonial-img img {
  width: 100px;
  height: 100px;
}

.testimonial-carousel .testimonial-img .btn-square {
  position: absolute;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial-carousel .owl-item .testimonial-text {
  margin-bottom: 30px;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transform: scale(.8);
  transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
  transform: scale(1);
}


/*** Footer ***/
.footer {
  color: #B0B9AE;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #B0B9AE;
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--light);
  letter-spacing: 1px;
  box-shadow: none;
}

.copyright {
  color: #B0B9AE;
  border-top: 1px solid rgba(255, 255, 255, .1);
}